<template>
  <div>
    <div class="container">
      <div class="navbar">
        <div class="navbar-left">
          <span @click="toIndex" v-if="showSiteInfo" class="logo cursor-pointer" :style="{backgroundImage:'url(\'' + (siteInfo.pic ? siteInfo.pic.logo2 : '') + '\')'}">
          </span>
          <span @click="toIndex" v-else class="logo cursor-pointer" :style="{backgroundImage:'url(\'' + nologo2 + '\')'}">
          </span>
          
        </div>
        <div class="navbar-right">
          <a href="/course">课程中心</a>
          <a href="/exam">在线题库</a>
          <a href="/news">新闻资讯</a>
          <a href="/teacher">行业导师</a>
        </div>
      </div>
    </div>
  </div>
</template>

<script type="text/javascript">
import {getLocalStorage,setLocalStorage} from '@/utils/auth' 
import nologo2 from '@/assets/images/nologo2.png'
export default {
  data() {
    return {
      siteInfo:'',
      nologo2,
	  showSiteInfo: true,
    }
  },
  components: {

  },
  props:{

  },
  created(){
    this.siteInfo = getLocalStorage('siteInfo')
  },
  methods:{
    toIndex(){
      this.$router.push('/')
    }
  }
}
</script>

<style scoped lang="scss">
.container{
  border-bottom:solid 1px #aaa; 
  .navbar{
    display:flex;
    flex-direction: row;
    height:100px;
    justify-content: flex-start;
    width:1200px;
    margin:0px auto;
    .navbar-left{
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      width:400px;
      .logo{
        display:inline-block;
        height:80px;
        width:240px;
        margin:20px 0px;
        background-repeat: no-repeat;
        background-size: contain;
        background-position: center center;
      }
      
    }
    .navbar-right{
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-end;
      width:800px;
      a{
        color:#fff;
        font-size:20px;
        height:24px;
        margin-left:50px;
        font-weight: bolder;
        display:inline-block;
        padding-bottom: 5px;
        &:hover{
          color:#333;
          box-shadow: 0px 2px 0px 0px #333;
        }
      }
    }
  }
}
</style>