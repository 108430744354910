<template>
  <div class="main-body">
    <div>
      <nav-bar></nav-bar>
    </div>
    <div class="main-container">
      <div class="reset-container">
        <!-- 注册标题 -->
        <div>
          <h3>重置密码</h3>
        </div>
        <!-- 登录区域 -->
        <div class="username-login">
          <div class="input mobile">
            <input type="text" placeholder="手机号码" v-model="phone">
          </div>
          <div class="check-code-container">
            <div class="input check-code">
              <input type="text" placeholder="短信验证码" v-model="sms">
            </div>
            <span class="ml-1">
                <el-button :type="$config.buttonType" @click="getCheckCode">发送验证码</el-button>
            </span>
          </div>
          <div class="input password">
            <input type="password" placeholder="输入新密码" v-model="password">
          </div>
          <div class="input password">
            <input type="password" placeholder="确认新密码" v-model="confirmPassword">
          </div>
          <div class="cannot-reset">
            想起密码了，<a class="login-immediately" href="/login">去登录</a>
          </div>
          <div class="reset-button">
            <el-button :type="$config.buttonType" class="button" size="medium" @click="toReset">重置</el-button>  
          </div> 
        </div>
      </div>
    </div>
    <div>
      <web-footer></web-footer>
    </div>
  </div>
</template>

<script type="text/javascript">
import NavBar from '@/views/user/components/NavBar'
import WebFooter from '@/views/user/components/WebFooter'
import {getLocalStorage,setLocalStorage,getSessionStorage,removeLocalStorage} from '@/utils/auth'
import {getSiteInfo,getResetSmsCheckCode,resetPassword} from '@/api/user'

export default {
  data() {
    return {
      siteInfo:'',
      password:'',
      phone:'',
      sms:'',
      confirmPassword:'',
      checkCode:''
    }
  },
  components: {
    NavBar,
    WebFooter
  },
  props:{

  },
  created(){
    //清除即存信息
    removeLocalStorage("token")
    removeLocalStorage("userInfo")
    this.findSiteInfo()
  },
  methods:{
    findSiteInfo(){
        this.siteInfo = getLocalStorage('siteInfo')
    },
    toReset(){
      //验证手机号码格式
      if(!(/^1(3|4|5|6|7|8|9)\d{9}$/.test(this.phone))){ 
        this.$notify.error({
            title:'错误',
            message:'手机号' + this.phone + '格式有误！'
        })
        return; 
      }
      //验证码是否正确
      if(this.sms.trim() == '' || this.sms != this.checkCode){
        this.$notify.error({
            title:'错误',
            message:'短信验证码不正确！'
        })
        return;
      }
      //判断是否为空
      if(this.password.trim() == ''){
        this.$notify.error({
            title:'错误',
            message:'密码不能为空！'
        })
        return;
      }
      //判断两次密码是否一致
      if(this.password != this.confirmPassword){
        this.$notify.warning({
            title:'警告',
            message:'确认密码与输入的新密码不一致！'
        })
        return; 
      }
      //以上都没有问题就重置密码
      resetPassword({
        phone:this.phone,
        password:this.password,
        code:this.sms
      }).then(
        res=>{
          if(res.code == 1){
            //更新成功之后，重新登录一下
            this.$notify.success({
                title:'成功',
                message:'密码重置成功，将返回登录页面！'
            })
            this.$router.push('/login')
          }else{
            this.$notify.error({
                title:'错误',
                message:'密码重置失败，请稍后重试！'
            })
          }
        }
      )
    },
    getCheckCode(){
      if(!(/^1(3|4|5|6|7|8|9)\d{9}$/.test(this.phone))){ 
        this.$notify.error({
            title:'错误',
            message:'手机号' + this.phone + '格式有误！'
        })
        return; 
      }
      //获取验证码
      getResetSmsCheckCode({
        phone:this.phone
      }).then(
        res=>{
          if(res.code == 1){
            this.checkCode = res.data
            this.$notify.success({
                title:'成功',
                message:'验证码已发送！'
            })
          }else{
            this.$notify.error({
                title:'错误',
                message:'手机号' + this.phone + '没有注册，不能重置密码！'
            })
          }
        }
      )
    }
  }
}
</script>

<style scoped lang="scss">
.main-body{
  background-image:url('../../assets/images/login-cover-5.jpg');
  background-position:center top;
  background-repeat: no-repeat;
  display:flex;
  flex-direction: column;
  .main-container{
    position: relative;
    width:1200px;
    margin:0px auto;
    min-height:570px;
    height:700px;
    max-height:760px;
    .reset-container{
      position: absolute;
      height:450px;
      width:400px;
      right:0px;
      top:50px;
      background-color:white;
      h3{
        text-align: center;
        font-size:18px;
        font-weight:bold;
        height:60px;
        line-height: 60px;
      }
      .input{
        display:block;
        height:20px;
        width:315px;
        padding:8px;
        outline:medium;
        color:$text-color;
        border:solid 1px #aaa;
        border-radius: 0px;
        margin-left:20px;
        margin-top:30px;
        padding-left:35px;
        input{
          font-size:16px;
          height:20px;
          width:260px;
          color:#333;
        }
      }
      .username{
        background-image:url('../../assets/images/login-icon-user.png');
        background-repeat: no-repeat;
        background-position: 5px center;
      }
      .password{
        background-image:url('../../assets/images/login-icon-lock.png');
        background-repeat: no-repeat;
        background-position: 5px center;
      }
      .mobile{
        background-image:url('../../assets/images/login-icon-mobile.png');
        background-repeat: no-repeat;
        background-position: 5px center;
      }
      .check-code-container{
        margin-top:30px;
        display:flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        .check-code{
          margin-top:0px;
          background-image:url('../../assets/images/login-icon-checkcode.png');
          background-repeat: no-repeat;
          background-position: 5px center;
          width:185px;
          input{
            width:170px;
          }
        }
      }
      .cannot-reset{
        width:320px;
        margin:10px auto;
        display:flex;
        flex-direction: row;
        justify-content:flex-end;
        .login-immediately{
          color:$theme-color;
        }
      }
      .reset-button{
        width:360px;
        margin:20px auto;
        height:30px;
        .button{
          width:100%;
        }
      }
      .protocol-container{
        width:350px;
        margin:20px auto;
        line-height:20px;
        a{
          color:$theme-color;
          &:hover{
            color:#333;
          }
        }
      }
    }
  }
}
</style>
<style lang="scss">
.login-tabs{
  width:360px;
  margin:0px auto;
  .el-tabs__item{
    width:180px;
    text-align: center;
    font-size:16px;
    font-weight:bold;
  }
  .el-tabs__item:hover{
    color:$theme-color;
  }
  .el-tabs__item.is-active{
    color:$theme-color;
  }
  .el-tabs__active-bar{
    background-color:$theme-color;
  }
}
</style>