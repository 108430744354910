<template>
  <div>
    <div class="container">
      <div class="left">
        <div v-if="showSiteInfo">
          <div class="copyright">
            版权所有：{{siteInfo.info ? siteInfo.info.copyright : ''}} {{siteInfo.info ? siteInfo.info.sitename : ''}}
          </div>
          <div class="telephone">
            联系方式：{{siteInfo.info ? siteInfo.info.tel : ''}} {{siteInfo.info ? siteInfo.info.serverTel : ''}}
          </div>
          <div class="address">
            公司地址：{{siteInfo.info ? siteInfo.info.address : ''}}
          </div>
        </div>
        <div class="icp">
          <a href="https://beian.miit.gov.cn">{{siteInfo.info ? siteInfo.info.icp : ''}} {{siteInfo.info ? siteInfo.info.ga_icp : ''}}</a> 
        </div>
      </div>
      <div class="right">
        <img v-if="showSiteInfo" :src="siteInfo.pic ? siteInfo.pic.wxLogo : ''" alt="">
      </div>
    </div>
  </div>
</template>

<script type="text/javascript">
import {getLocalStorage,setLocalStorage} from '@/utils/auth'
export default {
  data() {
    return {
      siteInfo:'',
	  showSiteInfo: true,
    }
  },
  components: {

  },
  props:{

  },
  created(){
    this.findSiteInfo()
  },
  methods:{
    findSiteInfo(){
		this.showSiteInfo = getLocalStorage('showSiteInfo')
        this.siteInfo = getLocalStorage('siteInfo')
    }
  }
}
</script>

<style scoped lang="scss">
.container{
  height:150px;
  color:#333;
  padding-bottom: 30px;
  display: flex;
  flex-direction: row;
  .left{
    width:900px;
    height:150px;
    line-height: 30px;
  }
  .right{
    width:300px;
    height:150px;
    text-align: right;
    img{
      height: 100px;
    }
  }
}
</style>